export default {
    wpApi: {
        host: "https://kcramsolutions.es/",
        base: "/wp-json/wp/v2",
        endpoints: {
            tecnologia: "tecnologia",
            proyecto: "proyecto",
            media: "media"
        }
    }
}